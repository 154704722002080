import { useEffect, useState } from "react";
//** External components */
import { Avatar, Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
//** Components */
import { Chip } from "components";
import { LocFastActions } from "pages/locations/components";
//** Context */
import { useLocationContext } from "pages/locations/context";
//** Hooks */
import { useGetSearchData } from "pages/locations/pages/locationsList/hooks";
import { useTranslation } from "react-i18next";

//**Icons */
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import DomainIcon from "@mui/icons-material/Domain";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

//** Models */
import { defaultLogoUrl } from "pages/locations/pages/locationsList";

export default function LocationCard({ locationId }: { locationId: string }) {
  //** States */
  const [load, setLoad] = useState(true);
  //**Context */
  const { logoText, name, isVisible, isHQLocation, organization } = useLocationContext();
  const { address1, website, applicableBrand, purpose, category, region, userCount } = useLocationContext();
  const { setUpdateToggle } = useLocationContext();

  //** Hooks */
  const { t } = useTranslation();
  const { purposeOptions, getPurposes } = useGetSearchData();

  //**Functions */

  const purposeCodes = purpose.map((item) => item.code);
  const locPurposes = purposeOptions.filter((item) => purposeCodes?.includes(`${item.code}`));

  useEffect(() => {
    getPurposes();
  }, []);

  return (
    <Card
      sx={{
        cursor: "pointer",
        width: "100%",
        minHeight: "280px",
        border: "2px solid",
        borderColor: "transparent",
      }}
    >
      <CardHeader
        title=""
        avatar={
          <Avatar
            sx={{
              display: load ? "none" : "block",
              ml: 4,
              width: 60,
              height: 60,
              backgroundColor: "background.default",
              "& .MuiAvatar-img": { objectFit: "contain !important" },
            }}
            src={typeof logoText === "string" ? logoText || defaultLogoUrl : defaultLogoUrl}
            alt={name}
            onLoad={(e) => {
              if (e.type === "load") {
                setLoad(false);
              }
            }}
          />
        }
        action={
          <Box sx={{ display: "flex" }} alignItems="center" height="60px">
            <Box alignContent="flex-end">
              {isHQLocation && <Chip label="HQ" size="small" color="primary" sx={{ mr: 2 }} />}

              {isVisible ? (
                <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
              ) : (
                <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
              )}

              <LocFastActions
                id={locationId}
                isHQ={isHQLocation}
                onSearch={() => {
                  setUpdateToggle((prev) => !prev);
                }}
                isDashboard
              />
            </Box>
          </Box>
        }
      />
      <CardContent sx={{ mx: 1, mt: 2 }}>
        <Box px={4} mb={8}>
          <Typography variant="h3" mb={2} fontWeight="700">
            {name}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <DomainIcon sx={{ fontSize: "1.6rem" }} color="secondary" />
            <Typography variant="h6" color="textSecondary" sx={{ cursor: "pointer" }} noWrap>
              {organization.label}
            </Typography>
          </Box>
        </Box>
        <Box mb={8}>
          <Box display="flex" alignItems="center" gap={2} mx={4} mb={2}>
            <FmdGoodOutlinedIcon sx={{ fontSize: "2.4rem" }} color="secondary" />
            <Typography variant="h5" color="textSecondary">
              {address1}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2} mx={4}>
            <LanguageIcon sx={{ fontSize: "2.4rem" }} color="secondary" />
            <Typography variant="h5" color="textSecondary" noWrap>
              {website}
            </Typography>
          </Box>
        </Box>
        <Box mb={4}>
          <Box display="flex" gap={2} mx={4} mb={2}>
            <StoreOutlinedIcon sx={{ fontSize: "2.4rem" }} color="secondary" />
            <Typography variant="h5" fontWeight={700}>
              {`${t("INPUT-BUSINESS")}: `}
              <span style={{ fontWeight: 500, fontSize: 14 }}>
                {locPurposes.map((item, i) => `${item.label}${i < locPurposes.length - 1 ? " - " : ""}`)}
              </span>
            </Typography>
          </Box>
          <Box display="flex" gap={2} mx={4} mb={2}>
            <InventoryOutlinedIcon sx={{ fontSize: "2.4rem" }} color="secondary" />

            <Typography variant="h5" fontWeight={700}>
              {`${t("INPUT-CATEGORY-PRODUCT")}: `}
              <span style={{ fontWeight: 500, fontSize: 14 }}>
                {category.map((item, i) => `${item}${i < category.length - 1 ? " - " : ""}`)}
              </span>
            </Typography>
          </Box>
          <Box display="flex" gap={2} mx={4}>
            <BusinessCenterOutlinedIcon sx={{ fontSize: "2.4rem" }} color="secondary" />
            <Typography variant="h5" fontWeight={700}>
              {`${t("INPUT-BRAND")}: `}
              <span style={{ fontWeight: 500, fontSize: 14 }}>
                {applicableBrand.map((item, i) => `${item.label}${i < applicableBrand.length - 1 ? " - " : ""}`)}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mx={4} mt={10}>
          <Box display="flex" alignItems="center" gap={4}>
            <GroupOutlinedIcon sx={{ fontSize: 24 }} />
            <Typography variant="h5" color="textSecondary">
              {userCount}
            </Typography>
          </Box>

          <Chip size="small" label={region.label} color="primary" sx={{ fontSize: "1.4rem" }} />
        </Box>
      </CardContent>
    </Card>
  );
}
