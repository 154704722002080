import { useState } from "react";
//** MUI Imports*/
import { Box, Checkbox, IconButton, Skeleton, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components Imports*/
import { FilterChips, InputApiCall, InputApiCallInfiniteScroll, Select, TableSearch } from "components";
import { RoleGuard, UnderMdGuard } from "guards";
import Translations from "layouts/components/Translations";
import { PrivateRoutes, Roles } from "models";
import UserBulkActions from "pages/users/components/molecules/UserBulkActions/UserBulkActions";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useGetReduxUser } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { useTranslation } from "react-i18next";
//** Icons */
import AddIcon from "@mui/icons-material/Add";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import InputSearch from "components/atoms/Input/InputSearch";
import ButtonExpand, { IDataButtonExpand } from "components/molecules/ButtonExpand/ButtonExpand";
import { useNavigate } from "react-router-dom";
import { capitalize } from "utilities";
import { intToShortNotation } from "utilities/HelpService";
import { useUsersListContext } from "../../../context";
import useUsersSearch from "../../../hooks/useUsersSearch";
import UserImportOnHeader from "./UserImportOnHeader";

interface HeaderProps {
  onSearch: () => void;
  isDashboard?: boolean;
  userOrgCounter?: number;
  redirectUrl?: string;
  loadingExternalData?: boolean;
  organizationId?: string;
  locationId?: string;
}

export default function AppListHeader({
  onSearch,
  isDashboard,
  redirectUrl,
  loadingExternalData,
  userOrgCounter,
  organizationId,
  locationId,
}: HeaderProps) {
  //** Context */
  const { usersHasFilter, setOpenImportModalUser } = useListAndSearchContext();
  const { usersArray, setSelected, selected } = useUsersListContext();
  const firstNameSearchFn = useUsersSearch().userFirstNameSearch;
  const lastNameSearchFn = useUsersSearch().userLastNameSearch;
  const emailSearchFn = useUsersSearch().userEmailSearch;
  const statusUserSearch = useUsersSearch().userStatusSearch;
  const roleSearch = useUsersSearch().userRoleSearch;
  const organizationUserSearch = useUsersSearch().userOrganizationSearch;

  //** Hooks */
  const matches = useMediaQuery("(max-width:600px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { getRoles, loading } = useGetRoles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  const { getOrganizations, orgPage, hasNextPage, orgData, setOrgPage, loadingOrg, setOrgData } = useGetSearchData();

  const numSelected = selected.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = usersArray.map((n) => ({ id: n.id, active: !n.isSuspended }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rowCount = usersArray.length;

  //**Chips */
  const filterChips = [
    {
      key: "first_name",
      label: "INPUT-FIRST-NAME",
      data: firstNameSearchFn.value,
    },
    {
      key: "last_name",
      label: "INPUT-LAST-NAME",
      data: lastNameSearchFn.value,
    },
    {
      key: "email",
      label: "INPUT-EMAIL",
      data: emailSearchFn.value,
    },
    {
      key: "role",
      label: "INPUT-ROLE",
      data: roleSearch.valueLabel,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusUserSearch.value,
    },
    {
      key: "organization",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationUserSearch.valueLabel,
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "first_name" | "last_name" | "email" | "role" | "status" | "organization") => {
    if (filterName === "first_name") firstNameSearchFn.clearByKey();
    if (filterName === "last_name") lastNameSearchFn.clearByKey();
    if (filterName === "email") emailSearchFn.clearByKey();
    if (filterName === "role") roleSearch.clearByKey();
    if (filterName === "status") statusUserSearch.clearByKey();
    if (filterName === "organization") organizationUserSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    firstNameSearchFn.clearByKey();
    lastNameSearchFn.clearByKey();
    emailSearchFn.clearByKey();
    roleSearch.clearByKey();
    statusUserSearch.clearByKey();
    organizationUserSearch.clearByKey();
    onSearch();
  };

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  const { userState } = useGetReduxUser();

  const hasSelectedItems = selected.length > 0;

  const dataButton: IDataButtonExpand[] = [
    {
      label: capitalize(t("BUTTON-CREATE").toLocaleLowerCase()),
      onClick: () => {
        navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}`);
      },
    },
    {
      label: capitalize(t("BUTTON-IMPORT").toLocaleLowerCase()),
      onClick: () => {
        setOpenImportModalUser(true);
      },
    },
  ];
  return (
    <Box pt={2} pb={6} mx={isDashboard ? 0 : 4}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={4}
        px={1}
        justifyContent="space-between"
        alignItems={isDashboard ? "center" : ""}
      >
        <Box>
          <Box ml={2}>
            {isDashboard ? (
              <Box display="flex">
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                  />
                </RoleGuard>
                <Box display="flex" alignItems="center" onClick={() => {}}>
                  <Typography width="100%" variant="h2" fontWeight="500">
                    <Translations text="GLOBAL-TITLE-USERS" />
                  </Typography>
                  {isDashboard && userOrgCounter === 0 ? (
                    <Skeleton variant="text" width={30} height={30} sx={{ ml: 2 }} />
                  ) : (
                    <Typography variant="h5" ml={2} mt={2}>{` (${intToShortNotation(userOrgCounter || 0)})`}</Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" onClick={() => {}}>
                <GroupOutlinedIcon sx={{ fontSize: 24, mr: 4 }} />
                <Typography width="100%" variant="h2" fontWeight={underMd ? "700" : "500"}>
                  <Translations text="GLOBAL-TITLE-ALL-USERS" />
                </Typography>
              </Box>
            )}
            {hasSelectedItems && (
              <Typography variant="h5" fontWeight="500" sx={{ ml: isDashboard ? 18 : 0 }}>
                {`${t("GLOBAL-SELECTED")} ${selected.length}`}
              </Typography>
            )}
          </Box>

          {hasSelectedItems && !isDashboard && (
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
              <Box display="flex" alignItems="center" ml={-4}>
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />

                <Box display="flex" justifyContent="space-between">
                  <UserBulkActions
                    onSearch={onSearch}
                    hasSelectedItems={hasSelectedItems}
                    isDashboard={isDashboard}
                    organizationId={organizationId}
                    locationId={locationId}
                  />
                </Box>
              </Box>
            </RoleGuard>
          )}
        </Box>

        <Box ml="auto" display="flex" flexWrap="wrap" alignItems={underMd ? "flex-end" : "center"} gap={isDashboard ? 0 : 4}>
          {isDashboard && hasSelectedItems ? (
            <UserBulkActions
              onSearch={onSearch}
              hasSelectedItems={hasSelectedItems}
              isDashboard={isDashboard}
              organizationId={organizationId}
              locationId={locationId}
            />
          ) : (
            <>
              <Box mt={underMd ? 4 : 0}>
                <TableSearch
                  isDashboard={isDashboard}
                  noOptionsComponent={
                    <Box>
                      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                        <InputSearch name="usr-first-name-search" {...firstNameSearchFn} label={`${t("INPUT-FIRST-NAME")}`} fullWidth />
                        <InputSearch name="usr-last-name-search" {...lastNameSearchFn} label={`${t("INPUT-LAST-NAME")}`} fullWidth />
                      </Stack>
                      <Box mb={4}>
                        <InputSearch name="usr-email-search" {...emailSearchFn} label={`${t("INPUT-EMAIL")}`} fullWidth />
                      </Box>
                      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                        <Select
                          name="usr-status-search"
                          value={statusUserSearch.value || ""}
                          label={`${t("INPUT-STATUS")}`}
                          setValue={statusUserSearch.onChange}
                          options={optionsStatus}
                        />
                        <InputApiCall
                          name="usr-role-search"
                          externalValue={{ label: roleSearch.valueLabel || "", id: roleSearch.valueId || "" }}
                          setPropValue={roleSearch.onChange}
                          apiCallFunction={getRoles}
                          loading={loading}
                          label={`${t("INPUT-ROLE")}`}
                        />
                      </Stack>
                      {!isDashboard && !userState?.organization?.id && (
                        <Box mb={4}>
                          <InputApiCallInfiniteScroll
                            initialValue={{ label: organizationUserSearch.valueLabel || "", id: organizationUserSearch.valueId || "" }}
                            name="usr-organization-search"
                            setPropValue={organizationUserSearch.onChange}
                            changeWithInputText
                            apiCallFunction={getOrganizations}
                            loading={loadingOrg}
                            label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                            hasNextPage={hasNextPage}
                            page={orgPage}
                            options={orgData}
                            setPage={setOrgPage}
                            setOptions={setOrgData}
                            search
                          />
                        </Box>
                      )}
                    </Box>
                  }
                  onReset={handleDeleteAll}
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    //** For input search is necesary to set the filter on the url when click on search */
                    //! Not set the filter on each change on the input becouse the url have a limit of updates per minute
                    firstNameSearchFn.handleSearch();
                    lastNameSearchFn.handleSearch();
                    emailSearchFn.handleSearch();
                    onSearch();
                  }}
                />
              </Box>
              <UnderMdGuard>
                {isDashboard ? (
                  <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
                    <IconButton
                      onClick={() => {
                        navigate(`${redirectUrl}`);
                      }}
                      disabled={loadingExternalData}
                      color="inherit"
                      sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, padding: "6px" }}
                    >
                      <AddIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                  </RoleGuard>
                ) : (
                  <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
                    <ButtonExpand data={dataButton} />
                  </RoleGuard>
                )}
              </UnderMdGuard>
            </>
          )}
        </Box>
      </Box>

      {usersHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" ml={4} my={6}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
      <UserImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
    </Box>
  );
}
