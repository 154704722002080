/* eslint-disable max-len */
import { useEffect, useRef, useState } from "react";
import { MarkerF, MarkerProps, InfoWindow } from "@react-google-maps/api";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOrganizationContext } from "pages";

import { Button } from "components/atoms";
import { RoleGuard } from "guards";
import { Roles } from "models";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";
import { ICoordinates } from "../Maps";

interface IMarkerProps extends MarkerProps {
  name: string;
  address1: string;
  id: string;
  mapId: string;
  loading?: boolean;
}
export default function MarkerOrg(props: IMarkerProps) {
  const markerRef: any = useRef(null);

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isUserAuthorized } = useAutorizeUserByRole();

  const { setOrgSelectedMark, activeLocationItemList, setActiveLocationItemList, setActiveLocationItemListCoordinates } =
    useOrganizationContext();

  const { setOrgLatitudeMap, setOrgLongitudeMap } = useOrganizationContext();

  const { id, name, address1, mapId, loading, position } = props;

  const markerIconFactory = (scale: number) => ({
    path: faLocationDot.icon[4] as string,
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "#ffffff",
    rotation: 0,
    scale: scale || 0.065,
    anchor: new google.maps.Point(
      faLocationDot.icon[0] / 2, // width
      faLocationDot.icon[1], // height
    ),
  });

  useEffect(() => {
    if (markerRef.current) {
      markerRef?.current.setIcon(markerIconFactory(mouseOver ? 0.08 : 0.065));
    }
  }, [mouseOver]);

  const handleOnLoad = (markerInstance: any) => {
    markerRef.current = markerInstance;
  };

  const handleMouseOver = () => {
    setMouseOver(true);
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };

  // eslint-disable-next-line no-shadow
  const handleChatClick = (type: "location", id: string) => {
    if (!activeLocationItemList?.includes(id)) setActiveLocationItemList([id]);
    setActiveLocationItemListCoordinates([
      {
        lat: position?.lat,
        lng: position?.lng,
        id,
      } as ICoordinates,
    ]);
  };

  const activeCondition = activeLocationItemList?.includes(id);

  return (
    <MarkerF
      onClick={() => {
        if (isUserAuthorized([Roles.superAdmin, Roles.organizationManager])) {
          handleChatClick("location", id);
        }
      }}
      icon={markerIconFactory(0.065)}
      onLoad={handleOnLoad}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      {...props}
    >
      {activeCondition && position.lat && position.lng && (
        <InfoWindow onCloseClick={() => setActiveLocationItemList([])} position={{ lat: +position.lat, lng: +position.lng }}>
          <Box sx={{ cursor: "pointer", maxWidth: 300 }}>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Box mr={6}>
                <Typography fontWeight={600} noWrap sx={{ color: "rgba(76, 78, 100, 0.7)" }}>
                  {name}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography noWrap variant="body2" sx={{ color: "rgba(76, 78, 100, 0.5)", whiteSpace: "normal" }}>
                {address1}
              </Typography>
            </Box>
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager]}>
              <Box display="flex" my={4} justifyContent="center">
                <Button
                  onClick={
                    loading
                      ? () => {}
                      : () => {
                          setOrgSelectedMark(mapId);
                          setOrgLongitudeMap(undefined);
                          setOrgLatitudeMap(undefined);
                        }
                  }
                  label={t("BUTTON-SELECT")}
                  name="select-maps-org"
                  endIcon={<>{loading && <CircularProgress color="info" size={15} />}</>}
                />
              </Box>
            </RoleGuard>
          </Box>
        </InfoWindow>
      )}
    </MarkerF>
  );
}
