/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useDropdownSearch, useDropdownStringSearch, useInputSearch } from "hooks";

export enum UserSearchFields {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  status = "status",
  role = "role",
  roleId = "roleId",
  organization = "organization",
  organizationName = "organizationName",
}

export default function useUsersSearch() {
  const { searchParamsUsers, setSearchParamsUsers } = useListAndSearchContext();
  const userFirstNameSearch = useInputSearch(searchParamsUsers, setSearchParamsUsers, UserSearchFields.firstName);
  const userLastNameSearch = useInputSearch(searchParamsUsers, setSearchParamsUsers, UserSearchFields.lastName);
  const userEmailSearch = useInputSearch(searchParamsUsers, setSearchParamsUsers, UserSearchFields.email);
  const userStatusSearch = useDropdownStringSearch(searchParamsUsers, setSearchParamsUsers, UserSearchFields.status);
  const userRoleSearch = useDropdownSearch(searchParamsUsers, setSearchParamsUsers, UserSearchFields.role, UserSearchFields.roleId);
  const userOrganizationSearch = useDropdownSearch(
    searchParamsUsers,
    setSearchParamsUsers,
    UserSearchFields.organizationName,
    UserSearchFields.organization,
  );

  return { userFirstNameSearch, userLastNameSearch, userEmailSearch, userStatusSearch, userRoleSearch, userOrganizationSearch };
}
