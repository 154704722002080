//** MUI Imports */
import { Box, Card, Divider, Fade, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { GoBackBreadcrumb, SectionsTitles, Spinner } from "components";
import { BasicInformationUsers, SuspendSection, UpdateUserButtons } from "pages/users/components";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
//** Utils */
import { PrivateRoutes } from "models";
import ModalGoBackUser from "pages/users/components/molecules/ModalGoBackUser/ModalGoBackUser";
import { useUserContext } from "pages/users/context";
import useUsers from "pages/users/hooks/useUsers";

export default function UserInformationView() {
  //** Context */
  //**Hooks */
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useUsers(`${id}`);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { userHasChanges, setGoBackModal } = useUserContext();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box px={underMd ? 4 : 0} mb={underMd ? 12 : 0}>
      <GoBackBreadcrumb
        label={t("GO-BACK-USER-LIST")}
        onClick={() => {
          const url = `/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`;
          if (userHasChanges) return setGoBackModal({ open: true, urlToGo: `${url}` });

          navigate(url);
        }}
      />
      <Fade in unmountOnExit timeout={500}>
        <div>
          <Card sx={{ py: 8, px: 6, mx: 6 }}>
            <SectionsTitles h2 title={`${t("USERS-INFO-TITLE")}`} subTitle={`${t("USERS-INFO-SUBTITLE")}`} />
            <BasicInformationUsers loadingOrganization={loading} loadingLoc={loading} />
            <SuspendSection />
            <Divider />
            <UpdateUserButtons />
          </Card>
        </div>
      </Fade>
      <ModalGoBackUser />
    </Box>
  );
}
