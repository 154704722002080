/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { Box, IconButton, Switch, useTheme } from "@mui/material";
import { ModalConfirm } from "components";
import { useFetchAndLoad, useGetReduxUser, useToastMui } from "hooks";

import { IBulkOrganization } from "pages/organizations/models";
import { bulkOrganizationAPI } from "pages/organizations/pages/organizationList";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { PrivateRoutes } from "models";
import { useDeleteOrganizations } from "pages/organizations/hooks";

interface OrgEditMobileProps {
  isActive: boolean;
  handleUpdateStatus?: () => void;
}

export default function OrgEditMobile({ isActive, handleUpdateStatus }: OrgEditMobileProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const params = useParams();
  const { t } = useTranslation();
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { deleteOrganizations } = useDeleteOrganizations();
  const theme = useTheme();
  const { userState } = useGetReduxUser();
  const { organization } = userState;

  const navigate = useNavigate();

  const handleChangeActive = async () => {
    try {
      const body: IBulkOrganization = { is_suspended: isActive, item_id: [params.id || ""] };
      setOpenModal(false);

      await callEndpoint(bulkOrganizationAPI({ body }), () => handleCreateToast(t("ALERT-ERROR-UPDATE"), "error"));
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      handleUpdateStatus && handleUpdateStatus();
    } catch (error) {
      console.log(error);
    }
  };
  // Handle  dialog
  const handleClickOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenDelete = () => setOpenModalDelete(true);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const handleEdit = () => {
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.EDIT_NAV}/${organization?.id || params.id}`);
  };

  const iconColor = theme.palette.mode === "dark" ? "white" : "black";

  return (
    <Box sx={{ zIndex: 10000000 }}>
      <Box display="flex" onClick={(e) => e.stopPropagation()}>
        <Switch checked={isActive} onChange={handleClickOpen} color="success" sx={{ pointerEvents: "all" }} size="medium" />
        <IconButton sx={{ backgroundColor: "background.default", pointerEvents: "all" }} onClick={handleOpenDelete}>
          <DeleteOutlinedIcon sx={{ fontSize: 20, color: iconColor }} />
        </IconButton>
        <IconButton sx={{ backgroundColor: "background.default", pointerEvents: "all", ml: 4, mr: 2 }} onClick={handleEdit}>
          <EditOutlinedIcon sx={{ fontSize: 20, color: iconColor }} />
        </IconButton>
      </Box>
      <ModalConfirm
        open={openModal}
        title={isActive ? t("BULK-STATUS-ORG-POPUP.TITLE-2") : t("BULK-STATUS-ORG-POPUP.TITLE")}
        description={isActive ? t("BULK-STATUS-ORG-POPUP") : t("BULK-STATUS-2-ORG-POPUP")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseModal}
        handleConfirm={handleChangeActive}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title="Delete organization?"
        description={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteOrganizations([params.id || ""]);
          setOpenModalDelete(false);
          navigate(`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
