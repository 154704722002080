//** HOOKS */
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
//**Redux */
import { Roles } from "models";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { createQuery } from "utilities";

//** Services */
import { exportModalActions } from "constants/globalConstants";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { downloadFileFromResponse } from "utilities/HelpService";
import { ILocationsSearchHook } from "../models";
import { useLocationListContext } from "../pages";
import { LocsSearchFields } from "../pages/locationsList/hooks/useLocationSearch";
import { getLocationExportAPI } from "../services";

export default function useExportLocation() {
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  //** Redux */
  const userData = useSelector((store: AppStore) => store.user);
  const { roles } = userData;
  const isAdmin = roles === Roles.superAdmin;
  const { id } = useParams();

  const { exportLocationFilter, setModalLoader } = useGlobalContext();
  const { selectedClaim, locationsArray } = useLocationListContext();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  //**Search */
  const { searchParamsLocs } = useListAndSearchContext();
  const nameLocSearch = searchParamsLocs.get(LocsSearchFields.name);
  const visibilitySearch = searchParamsLocs.get(LocsSearchFields.visibility);
  const marketSearch = searchParamsLocs.get(LocsSearchFields.marketId);
  const purposeSearch = searchParamsLocs.get(LocsSearchFields.purposeCodes);
  const organizationsearch = searchParamsLocs.get(LocsSearchFields.organization);

  const exportLocations = async () => {
    setOpenSuccessModal(true);

    setModalLoader({
      open: true,
      message: t("EXPORT-LOADER-TITLE"),
    });
    try {
      let query = createQuery<Partial<ILocationsSearchHook>>({
        name: nameLocSearch,
        market_or_country: marketSearch?.label ?? "",
        purpose: purposeSearch ?? "",
        is_visible: visibilitySearch?.length > 0 ? `${visibilitySearch === "Visible"}` : "",
      });
      const orgQuery = createQuery<Partial<ILocationsSearchHook>>({
        organization_id: userData?.organization?.id ? `${userData?.organization?.id}` : id || "",
      });

      const orgQueryFilter = createQuery<Partial<ILocationsSearchHook>>({
        organization_id: userData?.organization?.id ? `${userData?.organization?.id}` : id || organizationsearch || "",
      });

      let exportItems = "";

      if (exportLocationFilter === exportModalActions.EXPORT_CURRENT_PAGE) exportItems = locationsArray.map((item) => item.id).toString();
      if (exportLocationFilter === exportModalActions.EXPORT_ALL) query = { ...orgQuery, org_users: !isAdmin };
      if (exportLocationFilter === exportModalActions.EXPORT_SELECTED_ITEMS) exportItems = selectedClaim.map((item) => item.id).toString();
      if (exportLocationFilter === exportModalActions.EXPORT_CURRENT_SEARCH) query = { ...query, ...orgQueryFilter, org_users: !isAdmin };

      if (
        exportLocationFilter === exportModalActions.EXPORT_CURRENT_PAGE ||
        exportLocationFilter === exportModalActions.EXPORT_SELECTED_ITEMS
      ) {
        query = { search: exportItems || "empty" };
      }

      const response = await callEndpoint(getLocationExportAPI({ query }), async (error: any) => {
        const responseObj = await error?.response?.data?.text();
        handleCreateToast(JSON.parse(responseObj)?.message || t("ALERT-ERROR-ON-EXPORT"), "error");
      });
      downloadFileFromResponse(response, ".csv", "locations");
    } catch (error) {
      console.log(error);
    } finally {
      setModalLoader({
        open: false,
        message: t("EXPORT-LOADER-TITLE"),
      });
    }
  };

  return { loading, exportLocations, openSuccessModal, setOpenSuccessModal };
}
