/* eslint-disable consistent-return */
// ** React Imports
import { useState } from "react";

//** Hooks */
import { useFetchAndLoad, useSettings, useToastMui } from "hooks";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/globalContext";

//** Redux */
import { useDispatch } from "react-redux";

//** OTHERS */
import { IUserAPI, PrivateRoutes, Roles } from "models";
import { decodeJwtToken } from "frameworks_and_drivers/external_interfaces/jwt";
import { LocalStorageKeys, setInLocalStorage } from "utilities";
import config from "config/settings.json";
import { Settings } from "context/settingsContext";
import { userLoginAdapter } from "../adapter";
import { createUser, modifyUser, removeNotifications } from "../../../redux/slices/user.slice";

//** Services */
import { loginFunction } from "../services";

/**
 * Hooks used to login the user
 * @return {Function} login - function to login
 * @return {boolean} loading - boolean it's ture when the login are calling the api
 */
export default function useLogin() {
  //** Redux */
  const dispatch = useDispatch();

  //** Hooks */
  const navigate = useNavigate();
  const { callEndpoint, loading, error, setError } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();

  //** Context */
  const { rememberMe } = useGlobalContext();

  //** States */
  const [errorLabel, setErrorLabel] = useState<string>("");
  const [warning, setWarning] = useState<boolean>(false);

  //** Hook-> Funtion to hide the left bar */
  const { settings, saveSettings } = useSettings();
  const handleChange = (field: keyof Settings, value: Settings[keyof Settings]): void => {
    saveSettings({ ...settings, [field]: value });
  };

  const redirectToExpectedPage = () => {
    navigate(`/${PrivateRoutes.SELECT_ORGANIZARION}`, { replace: false });
  };

  const loginWithPingID = () => {
    // eslint-disable-next-line max-len
    const loginRedirectURL = `${config.AUTH_URI}/as/authorization.oauth2?response_type=code&client_id=${config.clientID}&redirect_uri=${config.redirectUri}`;
    window.location.href = loginRedirectURL;
  };

  /**
   ** Funtion to login the user
   * @return { login, loading, error, setError, errorLabel, warning}
   */
  // eslint-disable-next-line consistent-return
  const login = async (email: string | null, password: string | null) => {
    try {
      //Its necesary to set the email and password after try to login
      if (!email || !password) return setWarning(true);
      setWarning(false);

      //Login with akamai
      const response = await callEndpoint(await loginFunction(email, password));
      const { data } = response;

      //** Get payload token information */
      const decodeUser: IUserAPI = decodeJwtToken(data.result?.token);
      // eslint-disable-next-line consistent-return
      if (!decodeUser) return;

      //** Save user */
      dispatch(createUser({ token: data.result?.token }));
      dispatch(modifyUser(userLoginAdapter(decodeUser)));

      //Save the user login information if rememberme checkbox are active
      if (rememberMe) {
        setInLocalStorage(LocalStorageKeys.REMEMBER_TOKEN, btoa(`${password}/-/${email}`));
      } else {
        setInLocalStorage(LocalStorageKeys.REMEMBER_TOKEN, "");
      }

      //** If you came from a previous window navigate to it otherwise go to the organization selection page
      redirectToExpectedPage();

      // eslint-disable-next-line max-len
      if (decodeUser.roles_data[0].name === Roles.superAdmin) {
        handleChange("navHidden", false);
      }

      //** Delete notification when login as a diferent user */
      dispatch(
        removeNotifications({
          emailAssosiated: email,
        }),
      );
    } catch (err: any) {
      setErrorLabel(`${err?.response?.data?.message}`);
      handleCreateToast(`${err?.response?.data?.message}`, "error");
    }
  };

  return { login, loading, error, setError, errorLabel, warning, loginWithPingID };
}
