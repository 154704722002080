//** MUI Imports */
import { Card, CardContent } from "@mui/material";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Components */
import { AppListHeader } from "pages/users/pages/usersList/components/atoms";
import useGetUsersDashboard from "pages/users/pages/usersList/hooks/useUsersDashboard";
import { useParams } from "react-router-dom";
import UserDashboardItem from "../molecules/UserDashboardItem/UserDashboardItem";

interface UserDashboardViewProps {
  redirectUrl: string;
  loadingExternalData: boolean;
  locationId?: string;
  organizationId?: string;
  userOrgCounter?: number;
}
export default function UserDashboardView({
  redirectUrl,
  loadingExternalData,
  locationId,
  userOrgCounter,
  organizationId,
}: UserDashboardViewProps) {
  const params = useParams();
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;
  //** Hooks */
  const { loading, onSearch, hasNextPage, page, loadMoreUsers } = useGetUsersDashboard(
    !locationId ? params.id || `${organization?.id}` : "",
    locationId || "",
  );

  return (
    <Card>
      <CardContent>
        <AppListHeader
          isDashboard
          onSearch={onSearch}
          userOrgCounter={userOrgCounter}
          redirectUrl={redirectUrl}
          loadingExternalData={loadingExternalData}
          organizationId={organizationId}
          locationId={locationId}
        />
        <UserDashboardItem
          loading={loading}
          hasNextPage={hasNextPage}
          page={page}
          loadMoreUsers={loadMoreUsers}
          onSearch={onSearch}
          locationId={locationId}
        />
      </CardContent>
    </Card>
  );
}
