import { Theme, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";

interface IUnderMdGuardProps {
  children: string | ReactNode | ReactNode[];
  showOnMobile?: boolean;
}
export default function underMdGuard({ children, showOnMobile }: IUnderMdGuardProps) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  if (showOnMobile) return <>{underMd && <>{children}</>}</>;
  return <>{!underMd && <>{children}</>}</>;
}
