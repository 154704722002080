import { SxProps, Theme, Tooltip, TooltipProps, Typography } from "@mui/material";
import { ReactElement } from "react";

interface ITooltipAppProps {
  children: ReactElement;
  title: string;
  sxText?: SxProps<Theme>;
  placement?: TooltipProps["placement"];
}

export default function TooltipApp({ children, title, sxText, placement }: ITooltipAppProps) {
  return (
    <Tooltip
      placement={placement || "bottom"}
      title={
        <Typography color="common.white" sx={sxText}>
          {title}
        </Typography>
      }
    >
      {children}
    </Tooltip>
  );
}
