/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useDropdownArraySearch, useDropdownSearch, useDropdownStringSearch, useInputSearch } from "hooks";

export enum LocsSearchFields {
  name = "name",
  visibility = "visibility",
  purpose = "purpose",
  market = "market",
  marketId = "marketId",
  organization = "organization",
  purposeCodes = "purposeCodes",
  organizationName = "organizationName",
  keyword = "keyword",
  state = "state",
  city = "city",
}

export default function useLocationSearch() {
  const { searchParamsLocs, setSearchParamsLocs } = useListAndSearchContext();
  const locNameSearch = useInputSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.name);
  const locVisibilitySearch = useDropdownStringSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.visibility);
  const locMarketSearch = useDropdownSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.market, LocsSearchFields.marketId);
  const locPurposesSearch = useDropdownArraySearch(
    searchParamsLocs,
    setSearchParamsLocs,
    LocsSearchFields.purpose,
    LocsSearchFields.purposeCodes,
  );
  const locOrganizationSearch = useDropdownSearch(
    searchParamsLocs,
    setSearchParamsLocs,
    LocsSearchFields.organizationName,
    LocsSearchFields.organization,
  );

  //**Claim */
  const locKeywordSearch = useInputSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.keyword);
  const locStateSearch = useInputSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.state);
  const locCitySearch = useInputSearch(searchParamsLocs, setSearchParamsLocs, LocsSearchFields.city);

  return {
    locNameSearch,
    locVisibilitySearch,
    locMarketSearch,
    locPurposesSearch,
    locOrganizationSearch,
    locKeywordSearch,
    locStateSearch,
    locCitySearch,
  };
}
