import { Avatar, Box, Typography } from "@mui/material";
import { useState } from "react";
import OrgEditMobile from "./OrgEditMobile";
import OrgTabsMenuMobile from "./OrgTabsMenuMobile";

interface OrgDrawerHeaderProps {
  logo: string;
  name: string;
  url: string;
  isActive: boolean;
  handleUpdateStatus?: () => void;
}

export default function OrgDrawerHeader({ logo, name, url, isActive, handleUpdateStatus }: OrgDrawerHeaderProps) {
  const [load, setLoad] = useState(true);

  return (
    <Box mx={2}>
      <Box display="flex" gap={4} justifyContent="space-between" mb={4}>
        <Box display="flex" gap={4} width="50%">
          <Avatar
            sx={{
              display: load ? "none" : "block",
              ml: 4,
              width: 40,
              height: 40,
              backgroundColor: "background.default",
              "& .MuiAvatar-img": { objectFit: "contain !important" },
            }}
            src={typeof logo === "string" ? logo : ""}
            alt={name}
            onLoad={(e) => {
              if (e.type === "load") {
                setLoad(false);
              }
            }}
          />
          );
          <Box>
            <Box maxHeight={40} overflow="hidden">
              <Typography variant="h3" fontWeight="700">
                {name}
              </Typography>
            </Box>
            <Box maxHeight={40} overflow="hidden">
              <Typography variant="h5" color="textSecondary" sx={{ cursor: "pointer", maxWidth: "80%", overflow: "hidden" }}>
                {url}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <OrgEditMobile isActive={!isActive} handleUpdateStatus={handleUpdateStatus} />
        </Box>
      </Box>
      <OrgTabsMenuMobile />
    </Box>
  );
}
