/* eslint-disable no-shadow */
import { IDropdownOption } from "models";
import { IGoBackModal } from "pages/organizations";
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { IUsersListDOM } from "../models";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

export interface ICopyUserData extends Partial<IUsersListDOM> {
  initialUrl?: string;
  [index: string]: any;
}

type contextValues = {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  SBDEmail: string;
  setSBDEmail: Dispatch<SetStateAction<string>>;
  role: IDropdownOption;
  setRole: Dispatch<SetStateAction<IDropdownOption>>;
  organization: IDropdownOption[] | IDropdownOption;
  setOrganization: Dispatch<SetStateAction<IDropdownOption[] | IDropdownOption>>;
  clearBasicInformation: any;
  radioRole: string;
  setRadioRole: Dispatch<SetStateAction<string>>;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  dataCopy: ICopyUserData;
  setDataCopy: Dispatch<SetStateAction<ICopyUserData>>;
  updatedToggle: boolean;
  setUpdatedToggle: Dispatch<SetStateAction<boolean>>;
  view: string;
  setView: Dispatch<SetStateAction<string>>;
  isValidEmail: boolean;
  setIsValidEmail: Dispatch<SetStateAction<boolean>>;
  isValidSBDEmail: boolean;
  setIsValidSBDEmail: Dispatch<SetStateAction<boolean>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  alertRequiredFields: boolean;
  setAlertRequiredFields: Dispatch<SetStateAction<boolean>>;
  location: IDropdownOption;
  setLocation: Dispatch<SetStateAction<IDropdownOption>>;
  isPrimaryContact: boolean;
  setIsPrimaryContact: Dispatch<SetStateAction<boolean>>;
  userHasChanges: boolean;
  setUserHasChanges: Dispatch<SetStateAction<boolean>>;
  goBackModal: IGoBackModal | null;
  setGoBackModal: Dispatch<SetStateAction<IGoBackModal | null>>;
};

const defaultValues = {
  email: null,
  setEmail: () => {},
  SBDEmail: "",
  setSBDEmail: () => {},
  firstName: "",
  setFirstName: () => {},
  lastName: "",
  setLastName: () => {},
  phone: "",
  setPhone: () => {},
  role: { label: "", id: "" },
  setRole: () => {},
  organization: [],
  setOrganization: () => {},
  clearBasicInformation: () => {},
  radioRole: "Super Admin",
  setRadioRole: () => {},
  active: false,
  setActive: () => {},
  dataCopy: { id: "", firstName: "", lastName: "", phone: "", email: "", sbdEmail: "", organization: [] },
  setDataCopy: () => {},
  updatedToggle: false,
  setUpdatedToggle: () => {},
  view: "create",
  setView: () => {},
  isValidEmail: true,
  setIsValidEmail: () => {},
  isValidSBDEmail: true,
  setIsValidSBDEmail: () => {},
  title: "",
  setTitle: () => {},
  alertRequiredFields: false,
  setAlertRequiredFields: () => {},
  location: { label: "", id: "" },
  setLocation: () => {},
  isPrimaryContact: false,
  setIsPrimaryContact: () => {},
  userHasChanges: false,
  setUserHasChanges: () => {},
  goBackModal: null,
  setGoBackModal: () => {},
};

const UserContext = createContext<contextValues>(defaultValues);

const UserContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [radioRole, setRadioRole] = useState<string>("Super Admin");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [title, setTitle] = useState<string>("");
  const [SBDEmail, setSBDEmail] = useState<string>("");
  const [role, setRole] = useState<IDropdownOption>({ label: "", id: "" });
  const [organization, setOrganization] = useState<IDropdownOption[] | IDropdownOption>([]);
  const [location, setLocation] = useState<IDropdownOption>({ label: "", id: "" });
  const [active, setActive] = useState<boolean>(false);
  const [updatedToggle, setUpdatedToggle] = useState<boolean>(false);
  const [isPrimaryContact, setIsPrimaryContact] = useState<boolean>(false);

  const [view, setView] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidSBDEmail, setIsValidSBDEmail] = useState<boolean>(true);
  const [alertRequiredFields, setAlertRequiredFields] = useState<boolean>(false);

  const [userHasChanges, setUserHasChanges] = useState<boolean>(false);
  const [goBackModal, setGoBackModal] = useState<IGoBackModal | null>(null);

  const [dataCopy, setDataCopy] = useState<ICopyUserData>({
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    sbdEmail: "",
    organization: [],
  });

  //** Funtions to clear the states */
  const clearBasicInformation = () => {
    setEmail(null);
    setSBDEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setTitle("");
    setAlertRequiredFields(false);
    setIsPrimaryContact(false);
    setUserHasChanges(false);
  };
  const contextValues = useMemo(
    () => ({
      email,
      setEmail,
      SBDEmail,
      setSBDEmail,
      clearBasicInformation,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      phone,
      setPhone,
      role,
      setRole,
      organization,
      setOrganization,
      radioRole,
      setRadioRole,
      active,
      setActive,
      dataCopy,
      setDataCopy,
      updatedToggle,
      setUpdatedToggle,
      view,
      setView,
      isValidEmail,
      setIsValidEmail,
      isValidSBDEmail,
      setIsValidSBDEmail,
      title,
      setTitle,
      alertRequiredFields,
      setAlertRequiredFields,
      location,
      setLocation,
      isPrimaryContact,
      setIsPrimaryContact,
      userHasChanges,
      setUserHasChanges,
      goBackModal,
      setGoBackModal,
    }),
    [
      email,
      SBDEmail,
      clearBasicInformation,
      firstName,
      lastName,
      phone,
      role,
      organization,
      radioRole,
      active,
      dataCopy,
      updatedToggle,
      view,
      isValidEmail,
      isValidSBDEmail,
      title,
      alertRequiredFields,
      location,
      isPrimaryContact,
      userHasChanges,
      goBackModal,
    ],
  );

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("UserContext must be used within a UserContextProvider");
  }
  return context;
};

export { UserContext, UserContextProvider };
