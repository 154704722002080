import { FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import ExportModal from "components/molecules/ExportModal/ExportModal";
import Radio from "@mui/material/Radio";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "context/globalContext";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { exportModalActions } from "constants/globalConstants";
import { SuccessModalLocation } from "pages/locations";
import { Dispatch, SetStateAction } from "react";

export default function ModalUserExport({
  handleExport,
  open,
  handleClose,
  openSuccessModal,
  setOpenSuccessModal,
}: {
  handleExport: () => Promise<void>;
  open: boolean;
  handleClose: () => void;
  openSuccessModal: boolean;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
}) {
  //** States */
  const { selected } = useUsersListContext();
  const { usersHasFilter } = useListAndSearchContext();
  const { exportUserFilter, setExportUserFilter } = useGlobalContext();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportUserFilter((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <ExportModal open={open} handleClose={handleClose} handleConfirm={handleExport}>
        <Typography variant="h5">{t("USER-EXPORT-TITLE")} </Typography>
        <FormControl sx={{ ml: 4, mt: 4 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={exportModalActions.EXPORT_ALL}
            onChange={handleChange}
            value={exportUserFilter}
          >
            <FormControlLabel value={exportModalActions.EXPORT_ALL} control={<Radio />} label={t("USER-EXPORT-ITEM-2")} />
            <FormControlLabel
              value={exportModalActions.EXPORT_SELECTED_ITEMS}
              control={<Radio />}
              label={t("USER-EXPORT-ITEM-3")}
              disabled={selected.length === 0}
            />
            <FormControlLabel
              value={exportModalActions.EXPORT_CURRENT_SEARCH}
              control={<Radio />}
              label={t("USER-EXPORT-ITEM-4")}
              disabled={!usersHasFilter}
            />
          </RadioGroup>
        </FormControl>
      </ExportModal>
      <SuccessModalLocation
        open={openSuccessModal}
        title={t("EXPORT-SUCCESS-TITLE")}
        description={
          <Typography textAlign="center" variant="h5" mb={4}>
            {t("EXPORT-SUCCESS-DESC-1")}
            <Typography variant="h5" component="span" fontWeight="bold">
              {t("EXPORT-SUCCESS-DESC-2")}
            </Typography>
            {t("EXPORT-SUCCESS-DESC-3")}
          </Typography>
        }
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
      />
    </>
  );
}
