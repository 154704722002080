//** Componets */
import { Box, IconButton, useTheme } from "@mui/material";
//** Icon */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function GoBackArrowMobile({ onClick }: { onClick: () => void }) {
  const theme = useTheme();
  return (
    <Box
      position="fixed"
      top={80}
      left={15}
      zIndex={1000}
      sx={{ backgroundColor: "background.default" }}
      borderRadius="50%"
      onClick={onClick}
    >
      <IconButton>
        <ArrowBackIcon sx={{ fontSize: 20, color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000000" }} />
      </IconButton>
    </Box>
  );
}
