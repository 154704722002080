import { Box, Typography } from "@mui/material";
import { AutoComplete } from "components";
import { useEffect, useState } from "react";

interface IInputApiCalls {
  // eslint-disable-next-line no-unused-vars
  apiCallFunction: (value: string) => any;
  label: string;
  loading: boolean;
  setPropValue: any;
  changeWithInputText?: boolean; //when need a query to call the api with autocomplete input value
  secondaryFunction?: Function;
  externalValue?: any;
  name: string;
  disabled?: boolean;
  warning?: boolean;
  requiredFields?: boolean;
  alertLabel?: string;
  alwaysCallApi?: boolean;
  size?: "small" | "medium";
  changeControllerFn?: Function;
}
export default function InputApiCall({
  apiCallFunction,
  label,
  loading,
  changeWithInputText,
  setPropValue,
  secondaryFunction,
  externalValue,
  name,
  disabled,
  warning,
  requiredFields,
  alertLabel,
  alwaysCallApi,
  size,
  changeControllerFn,
}: IInputApiCalls) {
  const [value, setValue] = useState<any>("");
  const [options, setOptions] = useState<any[]>([]);

  const callApis = async (inputValue: string) => {
    if (alwaysCallApi) setOptions([]);
    if (options.length && !changeWithInputText && !alwaysCallApi) return;
    const arrayResponse: any = await apiCallFunction(inputValue);
    setOptions(arrayResponse);
  };

  useEffect(() => {
    if (!value) return;
    if (!options.length) return;

    const delayDebounceFn = setTimeout(() => {
      callApis(value);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <Box width="100%">
      <AutoComplete
        inputSize={size}
        warning={warning}
        disabled={disabled}
        name={name}
        fullWidth
        loading={loading}
        inputLabel={label}
        requiredFields={requiredFields}
        value={externalValue || value}
        options={options.sort((a, b) => {
          const nameA = a.label; // ignore upper and lowercase
          const nameB = b.label; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })}
        setValue={(valueState: any) => {
          setValue(valueState);
          setPropValue(valueState);
          changeControllerFn && changeControllerFn();

          if (secondaryFunction) secondaryFunction(valueState);
        }}
        onChangeInput={(valueInput: any) => {
          setValue(valueInput.target.value);
        }}
        onClick={() => callApis(value)}
      />
      {requiredFields && alertLabel && (
        <Typography component="span" color="error" ml={6} mt={2}>
          {alertLabel}
        </Typography>
      )}
    </Box>
  );
}
