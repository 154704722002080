import { useEffect, useState } from "react";
//**External libraries Imports */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//**Components Imports*/
import { Chip, TooltipError } from "components";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import { LocFastActions } from "pages/locations/components";
//**Guard */
import { RoleGuard, UnderMdGuard } from "guards";
//**Hooks and models*/
import useLocTables from "hooks/useLocTables";
import useLocationTableItems from "pages/locations/hooks/useLocationTableItems";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IDropdownOption, PrivateRoutes, Roles } from "models";
import { ILocationsListDOM } from "pages/locations/models";
//**Icons */
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import CheckIcon from "@mui/icons-material/Check";

//** Context */
import { useLocationListContext } from "../../../context";

interface ILocListProps {
  data: ILocationsListDOM;
  index: number;
  purposeOptions: IDropdownOption[];
  onSearch?: () => void;
  fromDashboard?: boolean;
}
export default function TableResponsiveRow({ data, index, purposeOptions, onSearch, fromDashboard }: ILocListProps) {
  //**Vars */
  const [expandPurposes, setExpandPurposes] = useState(false);
  const { locationsMapListArray, selectedClaim, statusFocus, clickedMarker, setClickedMarker } = useLocationListContext();
  //** Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setSelectedClaim, load, setLoad } = useLocationListContext();
  const { isSelected, handleClickTableCheckbox, handleClickTableCheckboxMobile } = useLocTables(
    setSelectedClaim,
    selectedClaim,
    locationsMapListArray,
  );
  const { handleTouchStart, handleTouchMove, handleTouchEnd, hoverItem, setHoverItem } = useLocationTableItems({
    handleClickTableCheckboxMobile,
  });
  //** Media querys */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smAbove = useMediaQuery("(min-width:900px)");
  const over1000px = useMediaQuery("(min-width:1000px)");

  //** Logic and functions */
  const locPurposes = purposeOptions.filter((item) => data.purposeCodes?.includes(`${item.code}`));
  const isItemSelected = isSelected(data.id);
  const isItemHover = data.id === hoverItem;

  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  useEffect(() => {
    if (clickedMarker) {
      statusFocus?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
    setClickedMarker(null);
  }, [clickedMarker]);

  const themeMui = useTheme();

  const checkboxActive = isItemHover || isItemSelected || selectedClaim.length > 0;
  const selectedOnMobile = isItemSelected && underMd;

  return (
    <ListItem key={`loc-${data.id}`} sx={{ px: 4, py: 0 }} ref={`${clickedMarker}` === data.id ? statusFocus : null}>
      <ListItemButton
        onClick={() => handleNavigate(data.id)}
        sx={{
          pl: 6,
          pr: 0,
          backgroundColor: "background.paper",
          py: underMd ? 4 : 0,
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,
          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
        }}
        onMouseEnter={smAbove ? () => setHoverItem(data.id) : undefined}
        onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
        onTouchStart={smAbove ? undefined : () => handleTouchStart(data.id, data.isHeadQuarter, !data.isVisible)}
        onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
        onTouchMove={smAbove ? undefined : () => handleTouchMove()}
      >
        {(selectedOnMobile || !underMd || selectedClaim.length > 0) && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
            <ListItemIcon onClick={(e) => e.stopPropagation()} sx={{ mr: 0 }}>
              {underMd && !fromDashboard ? (
                <>
                  {isItemSelected && (
                    <Checkbox
                      onClick={() => handleClickTableCheckbox(data?.id, data?.isHeadQuarter, !data?.isVisible)}
                      sx={
                        checkboxActive
                          ? { "& .MuiSvgIcon-root": { fontSize: 40 }, visibility: "visible", display: "block", p: 0, pl: 4, pr: 4, mr: 4 }
                          : { visibility: "hidden", display: "none" }
                      }
                      checked={isItemSelected}
                      checkedIcon={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ backgroundColor: themeMui.palette.primary.dark, borderRadius: "50%", width: 40, height: 40 }}
                        >
                          <CheckIcon sx={{ color: themeMui.palette.background.default, fontSize: "24px !important" }} />
                        </Box>
                      }
                    />
                  )}
                </>
              ) : (
                <Checkbox
                  onClick={() => handleClickTableCheckbox(data?.id, data?.isHeadQuarter, !data?.isVisible)}
                  sx={
                    checkboxActive
                      ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible", display: "block", p: 0, pl: 4, pr: 4 }
                      : { visibility: "hidden", display: "none" }
                  }
                  checked={isItemSelected}
                />
              )}
            </ListItemIcon>
          </RoleGuard>
        )}

        {selectedOnMobile && !fromDashboard ? null : (
          <ListItemAvatar sx={{ mr: 8 }}>
            <Box display="flex">
              <Box mt={0.4}>
                <Skeleton
                  sx={{ display: load[index] ? "block" : "none", ml: 4 }}
                  animation="wave"
                  variant="circular"
                  width={underMd ? 40 : 60}
                  height={underMd ? 40 : 60}
                />

                <Avatar
                  sx={{
                    display: load[index] ? "none" : "block",
                    ml: 4,
                    width: underMd ? 40 : 60,
                    height: underMd ? 40 : 60,
                    backgroundColor: "background.default",
                    "& .MuiAvatar-img": { objectFit: "contain !important" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (underMd) handleClickTableCheckbox(data?.id, data?.isHeadQuarter, !data?.isVisible);
                  }}
                  src={typeof data.organizationData.logo === "string" ? data.organizationData.logo : ""}
                  alt={data.name}
                  onLoad={(e: any) => {
                    if (e.type === "load") {
                      setLoad((prev: any) => {
                        const copy = prev;
                        copy[index] = false;
                        return [...copy];
                      });
                    }
                  }}
                />
              </Box>
            </Box>
          </ListItemAvatar>
        )}

        <Box width="100%" pr={7.2} display="flex" justifyContent="space-between">
          <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" alignItems="flex-start" flexDirection="column" width={over1000px ? "40%" : "100%"}>
              <Box display="flex" alignItems="center" mb={underMd ? 2 : 0}>
                <UnderMdGuard showOnMobile>
                  {data.isVisible ? (
                    <Box
                      mr={2}
                      bgcolor={(theme: Theme) => (theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light)}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  ) : (
                    <Box
                      mr={2}
                      bgcolor={(theme: Theme) => {
                        return theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;
                      }}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  )}
                </UnderMdGuard>
                <Box display="flex" alignItems="flex-start">
                  <Box mr={2}>
                    {data.warnings && data.warnings.length > 0 && <WarningTooltip table />}
                    {!data.hasRequiredFields && <TooltipError />}
                  </Box>
                  <Typography
                    variant={underMd ? "h5" : "h3"}
                    fontWeight={underMd ? "700" : "500"}
                    mb={underMd ? 0 : 2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {data.name}
                  </Typography>
                </Box>
              </Box>

              <Box width={underMd ? "55vw" : "30vw"} display="flex" alignItems={underMd ? "flex-start" : "center"}>
                <LocationOnOutlinedIcon sx={{ fontSize: "2rem", mr: 2, ml: underMd ? -2 : 0 }} color="secondary" />
                <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "80%" }}>
                  <Typography variant="h5" color="text.secondary">
                    {data.address.address1}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}>
                  <DomainOutlinedIcon sx={{ fontSize: 22, pb: 0 }} />
                </IconButton>
                <Typography variant="h5" color="text.secondary">
                  {data.organizationData.name}
                </Typography>
              </Box>
            )}
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}>
                  <StoreMallDirectoryOutlinedIcon sx={{ fontSize: 22 }} />
                </IconButton>
                <Typography variant="h5" color="text.secondary">
                  {locPurposes.map((item, i) => (
                    <>{i >= 2 && !expandPurposes ? null : `${item.label}${i < locPurposes.length - 1 ? " - " : ""}`}</>
                  ))}
                </Typography>
                {locPurposes.length > 2 && !expandPurposes && (
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ px: 0, minWidth: 40 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandPurposes(true);
                    }}
                  >
                    +{locPurposes.length - 2}
                  </Button>
                )}
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" width={underMd ? 50 : 140} justifyContent="flex-end">
            <Box
              sx={{
                display: "flex",
                alignItems: underMd ? "center" : "flex-end",
                justifyContent: "flex-start",
              }}
              gap={2}
            >
              {data.isHeadQuarter && <Chip label="HQ" size="small" color="primary" sx={{ mr: 2 }} />}

              <UnderMdGuard>
                {data.isVisible ? (
                  <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                ) : (
                  <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                )}
              </UnderMdGuard>

              <LocFastActions
                id={data.id}
                isHQ={data.isHeadQuarter}
                handleMouseLeave={() => setHoverItem("")}
                onSearch={() => {
                  if (onSearch) onSearch();
                }}
              />
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
