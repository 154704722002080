/* eslint-disable no-unused-vars */
//** MUI imports */
import { Grid, Skeleton, Stack, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Images */
import { EmptyListText } from "components";
import { useEmptyCardsLocationData } from "hooks";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import TableResponsiveRow from "pages/users/components/responsive/TableResponsiveRow/TableResponsiveRow";
import useGetUsersDashboard from "pages/users/pages/usersList/hooks/useUsersDashboard";
import useInfiniteScroll from "react-infinite-scroll-hook";

//** Components imports */
import { useUsersListContext } from "../../../context";
import { AppListHeader } from "../../atoms";
import AppListHeaderMobileUser from "../../mobile/AppListHeaderMobileUser";

export default function UsersTable({
  organizationId,
  fromDashboard,
  locationId,
  redirectUrl,
}: {
  organizationId?: string;
  fromDashboard?: boolean;
  locationId?: string;
  redirectUrl?: string;
}) {
  const { dataUsersCards } = useEmptyCardsLocationData();
  //** Context */
  const { usersArray } = useUsersListContext();
  const { usersHasFilter } = useListAndSearchContext();

  const { loading, hasNextPage, loadMoreUsers, onSearch, page } = useGetUsersDashboard(`${organizationId || ""}`, `${locationId || ""}`);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreUsers(),
    rootMargin: "0px 0px 400px 0px",
  });

  const hasData = usersHasFilter?.length === 0 ? usersArray.length !== 0 : true;

  const underHeightMobile = useMediaQuery("(max-height: 600px)");
  const lessHeight = underHeightMobile ? "250" : "225";
  const tableHeight = underMd ? lessHeight : "180";

  return (
    <>
      <Box mx={underMd ? 4 : 0} mt={underMd ? 8 : 0}>
        {!fromDashboard && <AppListHeader onSearch={onSearch} />}
        {fromDashboard && (
          <AppListHeaderMobileUser
            onSearch={onSearch}
            redirectUrl={redirectUrl || ""}
            loadingExternalData={!organizationId && !locationId}
          />
        )}
      </Box>

      <Box display="flex">
        <Box
          height={`calc(100dvh - ${`${tableHeight}px  - ${usersHasFilter ? "70px" : "0px"}`})`}
          overflow="scroll"
          width="100%"
          ref={rootRef}
        >
          {loading && usersArray.length === 0 ? (
            <Stack spacing={4} mt={8} px={underMd ? 4 : 0}>
              {Array.from({ length: 8 }).map((_, i) => (
                <Skeleton key={i} width="100%" height={50} variant="rounded" />
              ))}
            </Stack>
          ) : (
            <Box pt={2}>
              {usersArray?.map((item) => (
                <TableResponsiveRow data={item} onSearch={onSearch} fromDashboard={fromDashboard} />
              ))}
              {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

              <Box display="flex" justifyContent="center">
                {!hasData && (
                  <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                    {dataUsersCards.map((item, index) => (
                      <EmptyTableCards
                        key={index}
                        data={dataUsersCards[index]}
                        name="custom-radios-delivery"
                        gridProps={{ md: 12, sm: 12, xs: 12 }}
                      />
                    ))}
                  </Grid>
                )}
                {usersHasFilter?.length > 0 && usersArray.length === 0 && <EmptyListText />}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
