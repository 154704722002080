import { ModalConfirm } from "components";
import { useUserContext } from "pages/users/context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ModalGoBackUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { goBackModal, setGoBackModal } = useUserContext();

  const handleCloseDelete = () => {
    setGoBackModal((prev) => {
      if (prev) return { ...prev, open: false };
      return null;
    });
  };

  const handleConfirm = () => {
    if (goBackModal?.urlToGo === -1) navigate(-1);
    else navigate(`${goBackModal?.urlToGo}`, { replace: false });
    setGoBackModal(null);
  };

  return (
    <ModalConfirm
      open={goBackModal?.open || false}
      title={t("GLOBAL-GO-BACK-MODAL-TITLE")}
      description={t("GLOBAL-GO-BACK-MODAL-DESCRIPTION")}
      textButtonConfirm={t("BUTTON-GO-BACK")}
      handleClose={handleCloseDelete}
      handleConfirm={handleConfirm}
      sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
      sxTitle={{ fontSize: "2rem !important" }}
    />
  );
}
