import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

export default function SectionsTitles({
  title,
  subTitle,
  mb,
  h2,
  h3,
  fontWeight = 600,
}: {
  title: string;
  subTitle?: string;
  mb?: number;
  h2?: boolean;
  h3?: boolean;
  fontWeight?: number;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  let variant: "h1" | "h2" | "h3" | "h4" = "h1";
  if (variant === "h1") {
    variant = underMd ? "h3" : "h2";
  } else if (h2) {
    variant = underMd ? "h4" : "h2";
  } else if (h3) {
    variant = underMd ? "h4" : "h3";
  }
  return (
    <Box sx={{ mb: mb || 12 }}>
      <Typography variant={variant} sx={{ mb: 1.5, fontWeight, letterSpacing: "0.18px" }}>
        {title}
      </Typography>
      {subTitle && <Typography variant="h5">{subTitle}</Typography>}
    </Box>
  );
}
