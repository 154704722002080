import { Typography } from "@mui/material";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { actions, useGlobalContext } from "context/globalContext";
import { useFetchAndLoad, useGetReduxUser } from "hooks";
import { Roles } from "models";
import { notificationsAdapter, SuccessModalLocation } from "pages/locations";
import { ImportModalUsers } from "pages/users/components/molecules/ImportModalUsers";
import { importUsersAPI } from "pages/users/services";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCurrentDate } from "utilities/getCurrentDate";
import { addNotification } from "../../../../../../../redux/slices/user.slice";

export default function UserImportOnHeader({
  setCallStatus,
  callStatus,
}: {
  callStatus: "success" | "error" | null;
  setCallStatus: Dispatch<SetStateAction<"success" | "error" | null>>;
}) {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const { callEndpoint, loading } = useFetchAndLoad();
  const { userState } = useGetReduxUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openImportModalUser, setOpenImportModalUser } = useListAndSearchContext();
  const { setUserGetToggle } = useListAndSearchContext();
  const { setModalLoader } = useGlobalContext();

  //**Modal */
  const handleClose = () => setOpenImportModalUser(false);
  const handleConfirm = async (file: File) => {
    setCallStatus(null);
    handleClose();
    setOpenSuccessModal(true);
    setModalLoader({
      open: true,
      message: t("IMPORT-LOADER-TITLE"),
    });

    try {
      const response = await callEndpoint(
        importUsersAPI({ file, organization: userState.roles === Roles.superAdmin ? null : `${userState.organization?.name}` }),
      );
      const { data } = response;
      const adaptedData = notificationsAdapter(data);
      setCallStatus("success");

      dispatch(
        addNotification({
          id: `${Math.random()}`,
          name: actions.userImportSuccess,
          emailAssosiated: userState.email,
          date: getCurrentDate(),
          fileName: file.name,
          ...adaptedData,
        }),
      );
      setUserGetToggle((prev) => !prev);
    } catch (error: any) {
      console.log(error);
      setCallStatus("error");
      dispatch(
        addNotification({
          name: actions.userImportError,
          emailAssosiated: userState.email,
          error: `${error.response.data.message || "Importation is taking too long"}`,
          date: getCurrentDate(),
        }),
      );
    } finally {
      setModalLoader({
        open: false,
        message: t("IMPORT-LOADER-TITLE"),
      });
    }
  };
  return (
    <>
      <ImportModalUsers
        open={openImportModalUser}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        loading={loading}
        callStatus={callStatus}
      />
      <SuccessModalLocation
        open={openSuccessModal}
        title={t("IMPORT-SUCCESS-TITLE")}
        description={
          <Typography textAlign="center" variant="h5" mb={4}>
            {t("IMPORT-SUCCESS-DESC-1")}
            <Typography variant="h5" component="span" fontWeight="bold">
              {t("IMPORT-SUCCESS-DESC-2")}
            </Typography>
            {t("IMPORT-SUCCESS-DESC-3")}
          </Typography>
        }
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
      />
    </>
  );
}
