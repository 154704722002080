import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useFetchAndLoad, useToastMui } from "hooks";
import { importLocationsAPI } from "pages/locations/services";
import { Dispatch, SetStateAction, useState } from "react";

import { Typography } from "@mui/material";
import { actions, useGlobalContext } from "context/globalContext";
import { Roles } from "models";
import { ImportModalLocations, SuccessModalLocation } from "pages/locations/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { getCurrentDate } from "utilities/getCurrentDate";
import { addNotification } from "../../../../../../../redux/slices/user.slice";
import { notificationsAdapter } from "../../../adapters";

export default function LocationImportOnHeader({
  setCallStatus,
  callStatus,
}: {
  callStatus: "success" | "error" | null;
  setCallStatus: Dispatch<SetStateAction<"success" | "error" | null>>;
}) {
  //**Context */
  const { openImportModal, setOpenImportModal, setSearchToggleDash } = useListAndSearchContext();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { t } = useTranslation();
  const { setModalLoader } = useGlobalContext();

  const dispatch = useDispatch();
  const { callEndpoint, loading } = useFetchAndLoad();

  const { handleCreateToast } = useToastMui();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //**Modal */
  const handleClose = () => setOpenImportModal(false);

  const handleConfirm = async (file: File) => {
    setCallStatus(null);
    handleClose();
    setOpenSuccessModal(true);
    setModalLoader({
      open: true,
      message: t("IMPORT-LOADER-TITLE"),
    });

    try {
      const response = await callEndpoint(
        importLocationsAPI({ file, organization: userState.roles === Roles.superAdmin ? null : `${userState.organization?.name}` }),
      );
      const { data } = response;
      const adaptedData = notificationsAdapter(data);
      setCallStatus("success");
      handleCreateToast("Locations imported succesfully.Now you can find the new locations on the list.", "success");
      dispatch(
        addNotification({
          id: `${Math.random()}`,
          name: actions.locationImportSuccess,
          emailAssosiated: userState.email,
          date: getCurrentDate(),
          fileName: file.name,
          ...adaptedData,
        }),
      );
      setSearchToggleDash((prev: boolean) => !prev);
    } catch (error: any) {
      console.log(error);
      setCallStatus("error");
      dispatch(
        addNotification({
          name: actions.locationImportError,
          emailAssosiated: userState.email,
          error: `${error.response.data.message || "Importation is taking too long"}`,
          date: getCurrentDate(),
        }),
      );
    } finally {
      setModalLoader({
        open: false,
        message: t("IMPORT-LOADER-TITLE"),
      });
    }
  };

  return (
    <>
      <ImportModalLocations
        open={openImportModal}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        loading={loading}
        callStatus={callStatus}
      />
      <SuccessModalLocation
        open={openSuccessModal}
        title={t("IMPORT-SUCCESS-TITLE")}
        description={
          <Typography textAlign="center" variant="h5" mb={4}>
            {t("IMPORT-SUCCESS-DESC-1")}
            <Typography variant="h5" component="span" fontWeight="bold">
              {t("IMPORT-SUCCESS-DESC-2")}
            </Typography>
            {t("IMPORT-SUCCESS-DESC-3")}
          </Typography>
        }
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
      />
    </>
  );
}
