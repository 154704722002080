/* eslint-disable consistent-return */
//**React imports */
import { useEffect, useState } from "react";
//** COMPONTENS*/
import { CardHeaderLogin, CenterCard, Spinner } from "components";
import { PrivateRoutes, Roles, array20 } from "models";
//** MUI Imports */
import { Avatar, List, ListItemButton, ListItemText, Skeleton } from "@mui/material";
//** HOOKS*/
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//**External imports */
import { useNavigate } from "react-router-dom";
//**Services */
import { IOrganizationListDOM, organizationGetallAdapter } from "pages/organizations";
import { getOneUserAPI } from "pages/users/pages/usersInformation/services";
//**Context */
import { modifyUser, resetUser } from "../../redux/slices/user.slice";
import { AppStore } from "../../redux/store";

const style = {
  width: "100%",
  bgcolor: "background.paper",
};

interface IOrganizationsList {
  id: string;
  name: string;
}

export default function SelectOrganizationLogin() {
  //** HOOKS */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  //**Context */
  const userState = useSelector((store: AppStore) => store.user);

  //**States */
  const [organizationsList, setOrganizationsList] = useState<IOrganizationsList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [load, setLoad] = useState<boolean[]>(array20);

  const loadOrganizations = async () => {
    try {
      setLoading(true);

      //Calling get all API
      const response = await callEndpoint(getOneUserAPI(userState.id), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

      //Adapt data to DOM
      const organizationDataAdapted: IOrganizationListDOM[] = organizationGetallAdapter(response?.data?.result?.organization_data);
      dispatch(
        modifyUser({
          organizations: organizationDataAdapted,
          locationId: response?.data?.result?.location_id || "",
          locationName: response?.data?.result?.location_data?.name || "",
        }),
      );

      //Save the data into the state and redux
      setOrganizationsList(organizationDataAdapted);

      setLoading(false);
      return response;
    } catch (error: any) {
      handleCreateToast(
        error?.response?.data?.message === "User does not have access"
          ? "User doens't haver associated organizations"
          : error.response.data.message,
        "error",
      );
      dispatch(resetUser());
    }
  };

  useEffect(() => {
    //Call the organizations and dispatch on redux
    //If the user it's and admin or general view, it doesn't have any organization associated so the user must be redirect to the app
    if (userState.roles === Roles.superAdmin) {
      navigate(`/${PrivateRoutes.ORGANIZATIONS}`, { replace: false });
      dispatch(modifyUser({ organizations: [{}] }));
    } else {
      loadOrganizations();
    }

    //Applications setup
    const applicationResponse = [
      {
        id: "app-1",
        name: "RoBAC",
      },
      {
        id: "app-2",
        name: "Organization Manager",
      },
      {
        id: "app-3",
        name: "Warranty 4.0",
      },
    ];
    //Search current application (Organization Manager)
    const currentApp = applicationResponse.findIndex((item) => item.name === "Organization Manager");

    //Save the current aplication on redux
    dispatch(
      modifyUser({
        application: currentApp > 0 ? applicationResponse[currentApp] : applicationResponse[0],
        applications: applicationResponse,
      }),
    );
  }, []);

  useEffect(() => {
    if (organizationsList.length === 1) {
      dispatch(modifyUser({ organization: organizationsList[0] }));
      navigate(`/${PrivateRoutes.ORGANIZATIONS}`, { replace: false });
    }
    if ((userState.roles === Roles.multiOrgManager || userState.roles === Roles.multiOrgViewer) && organizationsList.length > 0) {
      dispatch(modifyUser({ organization: { label: "", id: "" } }));
      navigate(`/${PrivateRoutes.ORGANIZATIONS}`, { replace: false });
    }
  }, [organizationsList]);

  if (loading || organizationsList.length >= 1) {
    return <Spinner />;
  }
  return (
    <CenterCard>
      <CardHeaderLogin title={`${t("GLOBAL-TITLE-ORGANIZATIONS")}`} subTitle={`${t("SELECT-ORGANIZATION-DESCRIPTION")}`} />
      <List
        component="nav"
        aria-label="mailbox folders"
        sx={{ ...style, maxHeight: "330px", overflow: "scroll", backgroundColor: "background.default" }}
      >
        {organizationsList.map((item: any, index: number) => (
          <ListItemButton
            sx={{ py: 6, width: "70vw" }}
            key={item.id}
            onClick={() => {
              dispatch(modifyUser({ organization: item }));
              navigate(`/${PrivateRoutes.ORGANIZATIONS}`, { replace: false });
            }}
          >
            <Avatar
              sx={{ display: load[index] ? "none" : "block", "& .MuiAvatar-img": { objectFit: "contain !important" } }}
              src={item.logo}
              alt={item.name}
              onLoad={(e) => {
                if (e.type === "load") {
                  setLoad((prev: any) => {
                    const copy = prev;
                    copy[index] = false;
                    return [...copy];
                  });
                }
              }}
            />
            <Skeleton sx={{ display: load[index] ? "block" : "none" }} animation="wave" variant="circular" width={40} height={40} />
            <ListItemText primary={item.name} sx={{ ml: 8 }} />
          </ListItemButton>
        ))}
      </List>
    </CenterCard>
  );
}
