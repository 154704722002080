import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

export default function SearchMoreMapButton({
  loading,
  getCurrentMapData,
  left,
  top,
}: {
  loading: boolean;
  getCurrentMapData: () => void;
  left?: string;
  top?: string;
}) {
  const matches = useMediaQuery("(max-width:700px)");
  const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      top={top || "2%"}
      left={matches ? `${left || "35"}%` : `${left ? +left + 9 : "44"}%`}
      zIndex={998}
      height={40}
      sx={{ backgroundColor: "#FFFFFF", boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)", cursor: "pointer" }}
      borderRadius={8}
      display="flex"
      onClick={getCurrentMapData}
      alignItems="center"
    >
      <Box width={20} height={20} ml={6} mr={4}>
        {loading ? <CircularProgress size={15} /> : <SearchIcon sx={{ fontSize: 20 }} color="primary" />}
      </Box>

      <Typography variant="h6" color="#202124" sx={{ opacity: loading ? 0.5 : 1 }} fontWeight={500} mr={6}>
        {t("BUTTON-SEARCH-MAP", "Search this area")}
      </Typography>
    </Box>
  );
}
