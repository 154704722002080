import { useEffect, useState } from "react";
//** MUI Imports */
import { Box, Checkbox, Stack, Typography, useMediaQuery } from "@mui/material";

//** Hooks */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useFetchAndLoad } from "hooks";
import { useTranslation } from "react-i18next";
//** Constants */
import { Methods } from "constants/globalConstants";
//** Components */
import { ClaimMoreSearch, FilterChips, InputApiCall } from "components";
import { IRegion } from "models";
import { getAllRegionsAPI, useGetSearchData, useLocationListContext, useLocationSearch } from "pages/locations";
import InputSearch from "components/atoms/Input/InputSearch";
import { ClaimLocationButton } from "pages/locations/pages/locationClaim/components";
import { ClaimChips } from "../../../../locations/components/atoms/claimChips";

export default function ClaimSearch() {
  const { locationStatus, setClaimSearchToggle, claimSearchToggle } = useListAndSearchContext();
  const {
    selectedClaim,
    setSelectedClaim,
    locationsMapListArray,
    latitudeClaimMap,
    longitudeClaimMap,
    setLatitudeClaimMap,
    setLongitudeClaimMap,
  } = useLocationListContext();

  const nameSearchFn = useLocationSearch().locKeywordSearch;
  const marketSearch = useLocationSearch().locMarketSearch;
  const stateSearch = useLocationSearch().locStateSearch;
  const citySearch = useLocationSearch().locCitySearch;

  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);

  //** Hooks */
  const fetchRegion = useFetchAndLoad();
  const matches = useMediaQuery("(max-width:600px)");

  const { t } = useTranslation();

  const searchToggle = () => {
    setClaimSearchToggle(!claimSearchToggle);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    nameSearchFn.handleSearch();
    stateSearch.handleSearch();
    citySearch.handleSearch();
    searchToggle();
  };

  //**Chips */

  //!nombre market country - estado y ciudad
  const filterChips = [
    {
      key: "search",
      label: "Search",
      data: nameSearchFn.value,
    },
    {
      key: "state",
      label: "INPUT-STATE",
      data: stateSearch.value,
    },
    {
      key: "country",
      label: "INPUT-MARKET-COUNTRY",
      data: marketSearch?.valueLabel,
    },
    {
      key: "city",
      label: "INPUT-CITY",
      data: citySearch.value,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "search" | "state" | "country" | "city" | "coordinates") => {
    if (filterName === "search") nameSearchFn.clearByKey();
    if (filterName === "state") stateSearch.clearByKey();
    if (filterName === "country") marketSearch.clearByKey();
    if (filterName === "city") citySearch.clearByKey();
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }

    searchToggle();
  };
  const handleDeleteAll = () => {
    nameSearchFn.clearByKey();
    stateSearch.clearByKey();
    marketSearch.clearByKey();
    citySearch.clearByKey();
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    searchToggle();
  };

  //clear state when component unmount
  useEffect(() => {
    return () => {
      nameSearchFn.clearByKey();
      stateSearch.clearByKey();
      marketSearch.clearByKey();
      citySearch.clearByKey();
    };
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter, status: n.isVisible }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const numSelected = selectedClaim.length;
  const rowCount = locationsMapListArray.length;

  const { getCountryData, loadingCountry } = useGetSearchData();
  // eslint-disable-next-line consistent-return
  const getRegionData = async () => {
    //Get country data sort by name
    try {
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);
      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box px={6} py={4}>
        <Box display="flex">
          {locationStatus === Methods.UNCLAIMED && (
            <Box>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
              />
            </Box>
          )}
          <Box ml={2} display="flex" justifyContent="center" flexDirection="column" mb={selectedClaim.length !== 0 ? 7 : 0} width="100%">
            <Box display="flex" width="100%" justifyContent="space-between">
              <Typography variant="h3">{t("LOCATION-CLAIMS-ACTION")}</Typography>
              {selectedClaim.length > 0 && locationStatus === Methods.UNCLAIMED && (
                <ClaimLocationButton itemsId={selectedClaim.map((item) => item.id)} isItemSelected={false} />
              )}
            </Box>

            {selectedClaim.length !== 0 ? (
              <Box>
                <Typography variant="h5" color="primary">
                  {t("GLOBAL-SELECTED")} {selectedClaim.length}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h6" mt={2}>
                  {t("CLAIM-LOCATION-DESCRIPTION")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={2} p={4}>
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          mb={
            citySearch.value || marketSearch?.valueLabel || stateSearch.value || nameSearchFn.value || latitudeClaimMap || longitudeClaimMap
              ? 8
              : 0
          }
        >
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>

        <form noValidate onSubmit={handleSubmit} autoComplete="chrome-off">
          <Box display="flex" width="100%">
            <ClaimMoreSearch
              noOptionsComponent={
                <>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <InputSearch name="loc-claim-search" label={`${t("INPUT-SEARCH-MAP")}`} {...nameSearchFn} fullWidth />
                    <InputApiCall
                      name="loc-market-search"
                      setPropValue={marketSearch.onChange}
                      apiCallFunction={getCountryData}
                      loading={loadingCountry}
                      label={`${t("INPUT-MARKET")}`}
                      externalValue={{ label: marketSearch.valueLabel || "", id: marketSearch.valueId || "" }}
                    />
                  </Stack>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <InputSearch name="loc-state" {...stateSearch} label={`${t("INPUT-STATE")}`} fullWidth />

                    <InputSearch name="loc-city" {...citySearch} label={`${t("INPUT-CITY")}`} fullWidth />
                  </Stack>
                </>
              }
              onReset={() => {
                handleDeleteAll();
              }}
              onSubmit={handleSubmit}
            />
          </Box>
        </form>
      </Box>
      <ClaimChips />
    </Box>
  );
}
