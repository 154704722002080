import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

interface IRoleGuardProps {
  children: string | ReactNode | ReactNode[];
  authorizedRoles: string[];
  view?: boolean;
}
export default function RoleGuard({ children, authorizedRoles, view = true }: IRoleGuardProps) {
  const userState = useSelector((store: AppStore) => store.user);

  return <>{authorizedRoles.find((item) => item === userState?.roles) && view && <>{children}</>}</>;
}
