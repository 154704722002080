import { Avatar, Box, Typography } from "@mui/material";
import { Chip } from "components";
import { useLocationContext } from "pages/locations/context";
import { useState } from "react";
import LocEditMobile from "./LocEditMobile";
import LocTabsMenuMobile from "./LocTabsMenuMobile";

export default function LocDrawerHeader() {
  const [load, setLoad] = useState(true);
  const { logoText, name, isVisible, setIsVisible, isHQLocation } = useLocationContext();
  const handleUpdateStatus = () => setIsVisible((prev) => !prev);

  return (
    <Box mx={2}>
      <Box display="flex" gap={4} justifyContent="space-between" mb={4}>
        <Box display="flex" gap={4} width="50%">
          <Avatar
            sx={{
              display: load ? "none" : "block",
              ml: 4,
              width: 40,
              height: 40,
              backgroundColor: "background.default",
              "& .MuiAvatar-img": { objectFit: "contain !important" },
            }}
            src={typeof logoText === "string" ? logoText : ""}
            alt={name}
            onLoad={(e) => {
              if (e.type === "load") {
                setLoad(false);
              }
            }}
          />
          <Box>
            <Box maxHeight={40} overflow="hidden">
              <Typography variant="h3" fontWeight="700">
                {name}
              </Typography>
            </Box>
            <Box maxHeight={40} overflow="hidden">
              {isHQLocation && <Chip label="HQ" size="small" color="primary" sx={{ mr: 2 }} />}
            </Box>
          </Box>
        </Box>
        <Box minHeight={60}>
          <LocEditMobile isVisible={!isVisible} handleUpdateStatus={handleUpdateStatus} />
        </Box>
      </Box>
      <LocTabsMenuMobile />
    </Box>
  );
}
