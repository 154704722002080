/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, IconButton, Switch, Tooltip } from "@mui/material";
import { ModalConfirm } from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useFetchAndLoad, useToastMui } from "hooks";
import useDeleteUsers from "pages/users/hooks/useDeleteUsers";
import { IBulkUser } from "pages/users/models";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { bulkUserAPI } from "pages/users/services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BulkUserMoreOptions from "../BulkUserMoreOptions/BulkUserMoreOptions";

enum BulkActions {
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  DELETE = "DELETE",
}

interface IBulk {
  is_suspended?: boolean;
  action?: BulkActions;
}

export default function UserBulkActions({
  onSearch,
  hasSelectedItems,
  isDashboard,
  organizationId,
  locationId,
}: {
  onSearch: Function;
  hasSelectedItems: boolean;
  isDashboard?: boolean;
  organizationId?: string;
  locationId?: string;
}) {
  //** Context */
  const { selected, setSelected } = useUsersListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [activeCheck, setActiveCheck] = useState<boolean>(true);
  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { setUserGetToggle } = useListAndSearchContext();
  const { t } = useTranslation();

  //**update switch */
  useEffect(() => {
    if (selected.length >= 100) return setActiveCheck(false);

    const initialStatus = selected.reduce(
      (acc: { true: number; false: number }, claim: { id: string; active: boolean }) => {
        acc[`${claim.active}`] = (acc[`${claim.active}`] || 0) + 1;
        return acc;
      },
      { true: 0, false: 0 },
    );
    const mostFrequentStatus = initialStatus.true >= initialStatus.false;
    setActiveCheck(mostFrequentStatus);
  }, [selected]);

  //** Function */
  const handleBulk = async () => {
    try {
      const body: IBulkUser = { ...bulkObject, item_id: selected.map((item) => item.id) };
      setOpenModal(false);
      await callEndpoint(bulkUserAPI({ body }), (err: any) => handleCreateToast(err.response.data.message, "error"));
      setSelected([]);
      setUserGetToggle((prev: boolean) => !prev);
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
    } catch (error) {
      console.log(error);
    }
  };
  const { deleteUsers } = useDeleteUsers();
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    setOpenModal(true);
    setBulkObject(bulkQuery);
  };
  const handleClose = () => setOpenModal(false);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const modalTitle = bulkObject.action === BulkActions.ACTIVE ? `Activate ${selected.length} users?` : `Suspend  ${selected.length} users?`;

  const modalContent = bulkObject.action === BulkActions.ACTIVE ? t("BULK-USER-ACTIVATE") : `${t("BULK-USER-SUSPEND")}`;

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const action = event.target.checked ? BulkActions.ACTIVE : BulkActions.SUSPEND;
    handleClickOpen({ is_suspended: !event.target.checked, action });
    setActiveCheck(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <Switch checked={activeCheck} onChange={handleChangeActive} disabled={!hasSelectedItems} color="success" />
      <Tooltip title={t("ICON-DELETE")}>
        <IconButton onClick={() => setOpenModalDelete(true)} disabled={!hasSelectedItems}>
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 22 }} />
        </IconButton>
      </Tooltip>
      <BulkUserMoreOptions
        hasSelectedItems={hasSelectedItems}
        isDashboard={!!isDashboard}
        locationId={locationId}
        organizationId={organizationId}
      />

      <ModalConfirm
        open={openModal}
        title={modalTitle}
        description={modalContent}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={handleBulk}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title={`Delete ${selected.length} users?`}
        description={t("USERS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteUsers(selected.map((item) => item.id));
          setOpenModalDelete(false);
          onSearch();
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
