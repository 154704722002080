/* eslint-disable import/no-cycle */
import { IconButton, Tooltip } from "@mui/material";
import { ModalUserExport } from "pages/organizations/components/molecules/ModalUserExport";
import useExportUsers from "pages/users/hooks/useExportUsers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function BulkUserMoreOptions({
  hasSelectedItems,
  isDashboard,
  organizationId,
  locationId,
}: {
  hasSelectedItems: boolean;
  isDashboard: boolean;
  organizationId?: string;
  locationId?: string;
}) {
  //** States modal*/
  const [openExport, setOpenExport] = useState(false);
  const handleCloseExp = () => setOpenExport(false);
  const handleOpenExp = () => setOpenExport(true);

  //** Hooks */
  const { exportUsers, openSuccessModal, setOpenSuccessModal } = useExportUsers({
    organizationId: organizationId || "",
    locationId: locationId || "",
    embebed: isDashboard,
  });

  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("ICON-EXPORT")}>
        <IconButton
          disabled={!hasSelectedItems}
          onClick={() => {
            handleOpenExp();
          }}
        >
          <span className="material-symbols-outlined">export_notes</span>
        </IconButton>
      </Tooltip>
      <ModalUserExport
        open={openExport}
        handleExport={exportUsers}
        handleClose={handleCloseExp}
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
}
