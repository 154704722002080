import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
//** Component import */
import { Input, InputApiCall, InputApiCallInfiniteScroll } from "components";
import InputApiCallCheckboxInfiniteScroll from "components/atoms/InputApiCallCheckbox/InputApiCallCheckboxInfiniteScroll";
//** MUI Imports */
import { Box, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
//** Hooks */
import { useGetOrganizationsInfiniteScroll, useInputValueContext, useLocationInfiniteScroll } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
import { useUserContext } from "pages/users/context";
import { useTranslation } from "react-i18next";
//** Model */
import { EMAIL_REGEX, IDropdownOption, PrivateRoutes, Roles, RolesIds, SBD_EMAIL_REGEX } from "models";
import { RolesRadioButtons, UserViewsName } from "pages/users/models";
//** Redux */
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppStore } from "redux/store";
import useQuery from "utilities/getLocation";
import { UserRadioRoles } from "../UserRadioRoles";

export default function BasicInformationUsers({
  loadingOrganization,
  loadingLoc,
}: {
  loadingOrganization?: boolean | undefined;
  loadingLoc?: boolean | undefined;
}) {
  //** Context */
  const { firstName, lastName, email, phone, role, organization, radioRole, view, title, alertRequiredFields, location } = useUserContext();
  const { setFirstName, setLastName, setEmail, setPhone, setRole, setOrganization, setTitle, setLocation, setUserHasChanges } =
    useUserContext();
  const { isValidEmail, setIsValidEmail } = useUserContext();

  const params = useParams();
  const queryUrl = useQuery();
  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles: loggedUserRole, id, locationId, organization: loggedUserOrg } = userState;

  //**States */
  const firstNameInput = useInputValueContext("", setFirstName);
  const lastNameInput = useInputValueContext("", setLastName);
  const phoneInput = useInputValueContext("", setPhone);
  const titleInput = useInputValueContext("", setTitle);

  const comesFromLocDashboard = queryUrl.get("locId") || queryUrl.get("orgId");
  const comesFromOrgDashboard = queryUrl.get("locId");

  const changeControllerFn = () => setUserHasChanges(true);

  //** Request doesn't have radioRoles buttons*/
  //** Then we need to see if the role from the request is loc related to display the location input*/
  //** And we need to wait until the variable have information after display the field */

  const requestViewLocRole =
    window.location.pathname.includes("create") &&
    (role?.label === Roles.locationManager || role?.label === Roles.locationViewer) &&
    !Array.isArray(organization) &&
    !!organization?.id;

  const disableForViewOnlyRole = [Roles.multiOrgViewer, Roles.organizationViewer, Roles.locationViewer].includes(loggedUserRole as Roles);
  const isManagerUser = [Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager].includes(
    loggedUserRole as Roles,
  );
  const isMyProfile = params?.id === id;

  //** locations users only can edit users related to their own location*/
  const locManagerCanEdit =
    loggedUserRole === Roles.locationManager && radioRole === RolesRadioButtons.locRelated && locationId === location?.id;

  //** org users only can edit users related to their own org except the org managers that can edit all */
  const orgManagerCanEdit =
    loggedUserRole === Roles.multiOrgManager || loggedUserRole === Roles.superAdmin
      ? true
      : loggedUserRole === Roles.organizationManager && radioRole === RolesRadioButtons.locRelated;

  //**Users can edit their on profile */
  const canEditInformation = (isManagerUser && (locManagerCanEdit || orgManagerCanEdit)) || isMyProfile || view === "create";

  const onlyUpdateLocationRole =
    loggedUserRole === Roles.locationManager && [Roles.organizationManager, Roles.organizationViewer].includes(role?.label as Roles);

  const sbdEmailRolesInput = radioRole === RolesRadioButtons.superAdmin;

  const validateUserEmailByRole = (emailToValidate: string, isAdminRole?: boolean) => {
    const sbdEmailRoles = isAdminRole;
    const validEmail = sbdEmailRoles || sbdEmailRolesInput ? SBD_EMAIL_REGEX.test(emailToValidate) : EMAIL_REGEX.test(emailToValidate);
    setIsValidEmail(validEmail);
  };
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateUserEmailByRole(e.target.value);
  };

  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const { getRolesByUserRole, loading } = useGetRoles();

  const {
    getOrganizationsInfiniteScroll,
    hasNextPage,
    orgData,
    orgPage,
    setOrgPage,
    setOrgData,
    loading: loadingOrgsInf,
  } = useGetOrganizationsInfiniteScroll();
  const {
    getlocationsInfiniteScroll,
    locHasNextPage,
    locData,
    locPage,
    setLocPage,
    setLocData,
    loading: loadingLocInf,
  } = useLocationInfiniteScroll();

  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields : true;

  const rolesToDisplayRole = [RolesRadioButtons.orgRelated, RolesRadioButtons.locRelated, RolesRadioButtons.multiOrgRelated];
  const locRelatedHasOrgSelected = radioRole === RolesRadioButtons.locRelated && !Array.isArray(organization) && !!organization?.id;

  //**Set roles */
  useEffect(() => {
    if (radioRole === RolesRadioButtons.superAdmin && loggedUserRole === Roles.superAdmin) {
      setRole({ label: Roles.superAdmin, id: RolesIds.superAdmin });
    } else {
      if (view === UserViewsName.INFORMATION) return;
      setRole({ label: "", id: "" });
    }
  }, [radioRole]);

  //** Update org and location based on role */
  useEffect(() => {
    if (loggedUserRole === Roles.organizationManager || loggedUserRole === Roles.locationManager) {
      setOrganization({ label: loggedUserOrg?.name || "", id: loggedUserOrg?.id || "" });
      if (loggedUserRole === Roles.locationManager) {
        setLocation({ label: userState.locationName || "", id: userState.locationId || "" });
      }
    }
  }, [loggedUserRole]);

  return (
    <Box mb={12}>
      <UserRadioRoles
        disableForViewOnlyRole={disableForViewOnlyRole || !canEditInformation}
        validateUserEmailByRole={validateUserEmailByRole}
      />
      <Typography variant="h3" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("ORGANIZATION-BASIC-INFORMATION")}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="users-first-name"
          value={firstName}
          onChange={firstNameInput.onChange}
          label={`${t("INPUT-FIRST-NAME")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={firstName?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="users-last-name"
          value={lastName}
          onChange={lastNameInput.onChange}
          label={`${t("INPUT-LAST-NAME")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={lastName?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="users-email"
          value={email}
          onChange={onChangeEmail}
          label={`${t("INPUT-EMAIL")}*`}
          fullWidth
          disabled={view === UserViewsName.INFORMATION}
          error={email?.length === 0 || !isValidEmail || (email === null && isRequiedFieldEmpty)}
          helperText={
            // eslint-disable-next-line no-nested-ternary
            !isValidEmail && `${email || ""}`.length > 0
              ? sbdEmailRolesInput
                ? `${t("NEED-SBD-EMAIL-ADMIN")}`
                : `${t("INVALID-EMAIL")}`
              : `${t("ALL-REQUIRED.INPUT")}`
          }
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="users-phone"
          value={phone}
          onChange={phoneInput.onChange}
          label={`${t("INPUT-PHONE")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={phone?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
      </Stack>

      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="users-title"
          value={title}
          onChange={titleInput.onChange}
          label={`${t("INPUT-TITLE")}`}
          fullWidth
          disabled={!canEditInformation}
          sx={{ display: "flex" }}
          changeControllerFn={changeControllerFn}
        />

        <InputApiCall
          name="usr-role"
          externalValue={role}
          setPropValue={(value: any) => {
            setRole(value);

            setIsValidEmail(true);
          }}
          apiCallFunction={() => getRolesByUserRole(radioRole as any)}
          loading={loading}
          label={`${t("INPUT-ROLE")}*`}
          disabled={!canEditInformation || disableForViewOnlyRole || onlyUpdateLocationRole || radioRole === RolesRadioButtons.superAdmin}
          requiredFields={role?.label?.length === 0 && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          alwaysCallApi
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      {rolesToDisplayRole.find((item) => item === radioRole) && (
        <>
          {radioRole !== RolesRadioButtons.superAdmin && (
            <>
              <Typography variant="h3" sx={{ mb: 10, fontWeight: 600, letterSpacing: "0.18px" }}>
                {t("GLOBAL-TITLE-ORGNAIZATION")}
              </Typography>
              <>
                {!loadingOrganization && !loadingLoc ? (
                  <>
                    {radioRole === RolesRadioButtons.multiOrgRelated ? (
                      <InputApiCallCheckboxInfiniteScroll
                        initialValue={organization}
                        name="user-organization"
                        setPropValue={setOrganization as Dispatch<SetStateAction<IDropdownOption[]>>}
                        changeWithInputText
                        apiCallFunction={getOrganizationsInfiniteScroll}
                        loading={loadingOrganization || loadingOrgsInf || false}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        setOptions={setOrgData}
                        disabled={!canEditInformation || disableForViewOnlyRole}
                        requiredFields={Array.isArray(organization) && organization?.length === 0 && isRequiedFieldEmpty}
                        alertLabel={`${t("SELECT-ORG-INPUT")}`}
                        redirect
                        redirectUrl={`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`}
                        changeControllerFn={changeControllerFn}
                      />
                    ) : (
                      <InputApiCallInfiniteScroll
                        initialValue={organization}
                        name="loc-organization"
                        setPropValue={setOrganization}
                        changeWithInputText
                        apiCallFunction={getOrganizationsInfiniteScroll}
                        loading={loadingOrganization || loadingOrgsInf || false}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        disabled={
                          !canEditInformation ||
                          !!comesFromLocDashboard ||
                          disableForViewOnlyRole ||
                          loggedUserRole === Roles.locationManager ||
                          loggedUserRole === Roles.organizationManager
                        }
                        setOptions={setOrgData}
                        requiredFields={!Array.isArray(organization) && !organization?.label && isRequiedFieldEmpty}
                        changeControllerFn={changeControllerFn}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton variant="rounded" height={40} width="100%" />
                )}
              </>
              <>
                {(locRelatedHasOrgSelected ||
                  requestViewLocRole ||
                  (comesFromLocDashboard && radioRole === RolesRadioButtons.locRelated)) && (
                  <Box mt={8}>
                    <InputApiCallInfiniteScroll
                      initialValue={location}
                      name="loc.user"
                      setPropValue={setLocation}
                      changeWithInputText
                      apiCallFunction={(name: string) => {
                        getlocationsInfiniteScroll(name, !Array.isArray(organization) ? `${organization?.id}` : "");
                      }}
                      loading={loadingLoc || loadingLocInf || false}
                      label={`${t("GLOBAL-TITLE-LOCATION")} *`}
                      hasNextPage={locHasNextPage}
                      page={locPage}
                      options={locData}
                      setPage={setLocPage}
                      disabled={
                        !canEditInformation || disableForViewOnlyRole || loggedUserRole === Roles.locationManager || !!comesFromOrgDashboard
                      }
                      setOptions={setLocData}
                      requiredFields={!location?.label && isRequiedFieldEmpty}
                      alwaysCallApi
                      changeControllerFn={changeControllerFn}
                    />
                  </Box>
                )}
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
}
